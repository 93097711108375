<template>
  <div>
    <v-card>
      <v-container class="pt-1">
        <v-col class="pt-0">
          <v-row>
            <v-col class="pb-0">
              <h1 class="pt-2">Visitas Técnicas</h1>
            </v-col>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              small
              class="mr-3 mt-5"
              elevation="5"
              fab
              @click="clickAlert({})">
              <v-icon height="26" style="font-size: 16px !important"
                >fas fa-plus</v-icon>
            </v-btn>
          </v-row>
          <v-row>
            <!-- <v-col>
              <s-select-definition
              label="Tipo de visita"
              v-model="objFilter.TypeVisit"
              return-object
              :def="1202">
              </s-select-definition>
            </v-col> -->
            <v-col>
              <s-select
                label="Tipo de elaboración"
                item-text="Name"
                v-model="objFilter.TypeElaboration"
                item-value="Value"
                return-object
                :items="itemsTypeElaboration">

              </s-select>
            </v-col>
            <v-col>
              <s-date v-model="objFilter.DateBegin" label="Fecha Inicio"></s-date>
            </v-col>
            <v-col>
              <s-date v-model="objFilter.DateEnd" label="Fecha Fin"></s-date>
            </v-col>
           
          </v-row>
        </v-col>

        <v-row justify="center" class="mt-0" v-show="false">
          <v-col lg="2" class="">
            <s-date label="Fecha" v-model="filter.TvDate" />
          </v-col>
        </v-row>
      </v-container>
    </v-card>


    <div class="mt-3">
      <v-alert
        v-for="a in itemsVisit"
        v-model="alert"
        @click="clickAlert(a)"
        @touchend="clickAlert(a)"
        :color="a.TvID == null ? 'warning' : a.VstID ? 'success' : 'primary'" 
        class="pointer"
        border="left"
        elevation="10"
        centered
        colored-border
        icon="far fa-calendar-alt">
        <strong
          >{{ a.TypeVisitName }} {{ a.FagName }} - {{ a.CtvName }} -
          {{ a.VrtName }} - {{ a.FltName }}</strong
        >
        <p class="pb-0 pt-0 mt-0 mb-0">Dirección: {{ a.FagAddress }}</p>
        <p class="pb-0 pt-0 mt-0 mb-0">Ref. Parcela: {{ a.PlotReference }}</p>
        <p class="pb-0 pt-0 mt-0 mb-0">
          Latitud: {{ a.FagLatitude }} Longitud: {{ a.FagLatitude }}
        </p>
      </v-alert>
    </div>

    <v-dialog
      full-screen
      fullscreen
      v-model="dialogVisit"
      permanent
      v-if="dialogVisit"
      persistent
      width="100%">
      <visit-edit
        @close="
          dialogVisit = false;
          initializeData();"
        :isEditabled ="isEdit"
        :visitSchedule="visitSchedule"
      ></visit-edit>
    </v-dialog>
  </div>
</template>
<script>
import VisitEdit from "@/views/TechnicalAssistance/Visits/VisitEdit";
import _sVisit from "@/services/Technicalassistance/TasTechnicalVisitService.js";
export default {
  components: {
    VisitEdit,
  },

  data() {
    return {
      itemsTypeElaboration : [], 
      objFilter: {},
      visitSchedule: null,
      alert: true,
      isEdit: false,
      itemsVisit: [],
      dialogVisit: false,
      filter: {
        TvDate: null,
        TypeVisit: 0,
      },
    };
  },

  mounted() {
    this.initializeData();
    this.itemsTypeElaboration = [
      { Name :"Visita Programada", Value  : "1"},
      { Name : "Visita Realizada / No programada", Value : "2"},
      { Name : "Visita Programada / Realizada  ", Value : "3"},
    ]
    
  },
  methods: {

    initializeData() {
      let obj = {
        NtpID: this.$fun.getUserInfo().NtpID,  // son las visitas programadas para ese usuario
        TvDate: this.filter.TvDate,
        DateBegin : this.objFilter.DateBegin,
        DateEnd : this.objFilter.DateEnd,
        TypeElaboration : this.objFilter.TypeElaboration ? this.objFilter.TypeElaboration.Value : null ,
        TypeVisit: this.filter.TypeVisit,

      };
      console.log('datos del objeto', obj);
      console.log(this.$fun.getUserInfo().NtpID);
      _sVisit.BySupervisorByDate(obj, this.$fun.getUserID()).then((r) => {
        this.itemsVisit = r.data;
        console.log('datos para mostrar', this.itemsVisit)
      });
    },

    clickAlert(a) {
      console.log('datos del click', a)
      if (a.VstID == null) {

        this.dialogVisit = true;
        this.isEdit = false;
        this.visitSchedule = a;
      } else {
        
        this.dialogVisit = true;
        this.isEdit = true;
        this.visitSchedule = a;
      }
    },
  },
  watch : {

      "objFilter.DateBegin"(){
        this.initializeData();
      },

      "objFilter.DateEnd"(){
        this.initializeData();
      },
      
      "objFilter.TypeElaboration"(){
        this.initializeData();
      }

  }
};
</script>
<style  scope>
.pointer {
  cursor: pointer;
}
.pointer:hover {
  border-style: dashed;
}
</style>